import { Link } from "gatsby";
import React from "react";

import "./nextgen-container.scss"

let nextgenTitle = '';
let nextgenSubtitle = '';
let meetButtonText = '';
let intakeButtonText = '';

class NextgenContainer extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        if (this.props.titles != null) {
            for (const title of this.props.titles) {
                if (title.indicator === 'nextgen-title') {
                    nextgenTitle = title.value;
                }
                if (title.indicator === 'nextgen-subtitle') {
                    nextgenSubtitle = title.value;
                }
                if (title.indicator === 'demo-container-meet-button') {
                    meetButtonText = title.value;
                }
                if (title.indicator === 'intake-button-text') {
                    intakeButtonText = title.value;
                }
            }
        }
        this.setState({
            nextgenTitle,
            nextgenSubtitle,
            meetButtonText,
            intakeButtonText
        });
    }

    render() {
        return <div className="nextgen-container">
            <h2>{nextgenTitle}</h2>
            <p>{nextgenSubtitle}</p>
            <div className="row">
                <ul className="button-menu">
                    <li className="demo-button-container">
                        <button className="demo-button">
                            <Link id="demo-container-button" key="demo-button" to={`/nextgen-travel-scan`}>Plan intake gesprek</Link>
                        </button>
                        <p>{intakeButtonText}</p>
                    </li>
                    <li className="more-about-container">
                        <button id="meet-container-button" className="more-about-button">
                            <Link key="meet-button" to={`/demo-afspraak`}>Verkennend gesprek</Link>
                        </button>
                        <p>{meetButtonText}</p>
                    </li>
                </ul>
            </div>
        </div>;
    }
}

export default NextgenContainer;
